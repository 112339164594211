import React from 'react'
import SEO from '../components/seo'
import ClientOnly from '../components/clientOnly'
import styles from '../styles/not-found.module.css'

const NotFoundPage = () => (
	<>
		<SEO title='404: Not found' />
		<ClientOnly>
			<div className={styles.notFound} style={{height: '100vh'}}>
				<h1>{`404: Not Found`}</h1>
				<p>{`You just hit a route that doesn't exist`}</p>
			</div>
		</ClientOnly>
	</>
)

export default NotFoundPage
